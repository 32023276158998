// React
import React, { useState } from 'react';

// Design
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@material-ui/core/styles';

// Internal
import Supervision from '../..';
import { appUser } from '../../../../store/app/slice';
import Monitor from './views/monitor';
import Tables from './views/tables';
import ClockPunch from './views/clockPunch';
import ServiceTickets from './views/serviceTickets';
import APIReport from './views/api';
import WaitingInLineLogs from './views/waitingInLineLogs';

// Third-party
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const VIEWS = {
  Monitor: ({ ...props }) => <Monitor props={{ ...props }} />,
  Tabelas: ({ ...props }) => <Tables props={{ ...props }} />,
  Ponto: ({ ...props }) => <ClockPunch props={{ ...props }} />,
  'Tickets de Serviço': ({ ...props }) => (
    <ServiceTickets props={{ ...props }} />
  ),
  'Logs de Espera': ({ ...props }) => (
    <WaitingInLineLogs props={{ ...props }} />
  ),
  API: ({ ...props }) => <APIReport props={{ ...props }} />,
};

export default function Reports() {
  // eslint-disable-next-line
  const user = useSelector(appUser);
  const classes = useStyles();

  const startDate = new Date();
  const [selectedStartDate, setSelectedStartDate] = useState(
    new Date(startDate.setHours(startDate.getHours() - 24))
      .toISOString()
      .split('T')[0]
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    new Date().toISOString().split('T')[0]
  );
  const [selectedView, setSelectedView] = useState('Monitor');
  const [search, setSearch] = useState(false);

  const handleChangeView = (event) => {
    setSelectedView(event.target.value);
  };

  console.log({ selectedStartDate, selectedEndDate });
  return (
    <Box>
      <Supervision />
      <Box
        sx={{
          display: 'flex',
          margin: 0,
          width: '100%',
          height: 'calc(100vh - 4.063rem)',
          flexDirection: 'column',
          flexGrow: 1,
          // overflowY: 'scroll'
        }}
      >
        <Box
          sx={{
            width: '100%',
            // margin: '0.5rem 0 0 0.5rem',
            padding: '0.6rem 0 0 0.25rem',
            height: '6rem',
            display: 'flex',
            gap: '0.5rem',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box>
            <TextField
              id="date"
              label="Data Inicial"
              type="date"
              defaultValue={selectedStartDate}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setSelectedStartDate(e.target.value)}
            />
          </Box>
          <Box>
            <TextField
              id="date"
              label="Data Final"
              type="date"
              defaultValue={selectedEndDate}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setSelectedEndDate(e.target.value)}
            />
          </Box>
          <Box>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Visualização
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedView}
                label="Visualização"
                onChange={handleChangeView}
              >
                {Object.keys(VIEWS).map((view) => (
                  <MenuItem value={view} key={view}>
                    {view}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Button
            variant="contained"
            onClick={() => setSearch(true)}
            disabled={search}
          >
            Pesquisar
          </Button>
        </Box>
        <Box
          sx={{
            // width: '100%',
            // marginTop: '2rem',
            height: 'calc(100vh - 4.063rem)',
            overflowY: 'auto',
          }}
        >
          {VIEWS[selectedView]({
            startDate: selectedStartDate,
            endDate: selectedEndDate,
            search,
            setSearch,
          })}
        </Box>
      </Box>
    </Box>
  );
}
